var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "automation" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("triggers.triggers"),
          actionButtonIcon: "fa fa-plus",
          actionButtonDesc: "Add A New Trigger"
        },
        on: { action: _vm.onClickAddTrigger }
      }),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.$store.getters.triggers,
            stripe: "",
            height: "500"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: _vm.$t("triggers.name"),
              width: "140",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "condition.description",
              label: _vm.$t("triggers.condition")
            }
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("triggers.actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          scope.row.actions
                            .map(function(action) {
                              return action.description
                            })
                            .join(", ")
                        )
                      }
                    })
                  ]
                }
              }
            ])
          }),
          false
            ? _c("el-table-column", {
                attrs: {
                  prop: "createDate",
                  label: "Create Date",
                  sortable: ""
                }
              })
            : _vm._e(),
          false
            ? _c("el-table-column", {
                attrs: {
                  prop: "lastModifiedDate",
                  label: "Modified Date",
                  sortable: ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "lastExecutionDate",
              label: _vm.$t("triggers.execution-date"),
              width: "230",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "140",
              label: _vm.$t("triggers.visible")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: function($event) {
                          return _vm.switchVisible(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.visible,
                        callback: function($$v) {
                          _vm.$set(scope.row, "visible", $$v)
                        },
                        expression: "scope.row.visible"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              width: "140",
              label: _vm.$t("triggers.actions")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "icon",
                        on: {
                          click: function($event) {
                            return _vm.onClickEditTrigger(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-edit" })]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "icon",
                        on: {
                          click: function($event) {
                            return _vm.removeTrigger(scope.row.id)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }