<template>
  <div class="automation">
    <app-navigation :title="$t('triggers.triggers')" actionButtonIcon="fa fa-plus" actionButtonDesc="Add A New Trigger"
      @action="onClickAddTrigger"></app-navigation>

    <el-table :data="$store.getters.triggers" stripe height="500">

      <el-table-column prop="name" :label="$t('triggers.name')" width="140" sortable>

      </el-table-column>

      <el-table-column prop="condition.description" :label="$t('triggers.condition')">
      </el-table-column>

      <el-table-column :label="$t('triggers.actions')">
        <template slot-scope="scope">
          <span v-html="scope.row.actions.map(action => action.description).join(', ')"></span>
        </template>
      </el-table-column>

      <el-table-column v-if="false" prop="createDate" label="Create Date" sortable>

      </el-table-column>

      <el-table-column v-if="false" prop="lastModifiedDate" label="Modified Date" sortable>

      </el-table-column>

      <el-table-column prop="lastExecutionDate" :label="$t('triggers.execution-date')" width="230" sortable>

      </el-table-column>

      <el-table-column align="right" width="140" :label="$t('triggers.visible')">

        <template slot-scope="scope">
          <el-switch v-model="scope.row.visible" @change="switchVisible(scope.row)"></el-switch>
        </template>

      </el-table-column>

      <!-- <el-table-column
              prop="favorite"
              :label="$t('triggers.visible')"
              width="140">

        <template slot-scope="scope">
          <el-switch v-model="scope.row.visible"></el-switch>
        </template>

      </el-table-column> -->

      <el-table-column align="right" width="140" :label="$t('triggers.actions')">

        <template slot-scope="scope">
          <span class="icon" @click="onClickEditTrigger(scope.row)"><i class="far fa-edit"></i></span>
          <span class="icon" @click="removeTrigger(scope.row.id)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "Triggers",
  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {

    }
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    onClickAddTrigger: function () {
      this.$router.push({ name: 'TriggerAdd' })
    },

    onClickEditTrigger: function (trigger) {
      this.$store.commit("setTriggerData", { ...trigger })
      this.$router.push({ name: 'TriggerEdit', params: { id: trigger.id } })
    },

    switchVisible: function (trigger) {
      console.log("switchVisible", trigger)
      // trigger.visible = !trigger.visible
      this.$store.dispatch("editTrigger", trigger)
    },

    removeTrigger: function (triggerId) {
      this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
        confirmButtonText: this.$t('alert.button.ok'),
        cancelButtonText: this.$t('alert.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('removeTrigger', triggerId).then(response => {
          this.$message({
            message: this.$t(response.message),
            type: 'success'
          })
        }).catch(reason => {
          this.$message({
            message: this.$t(reason),
            type: 'error'
          })
        })
      })
    },
    async fetchData() {
      try {
        await this.$store.dispatch("getTriggers");
        await this.$store.dispatch("getScenes")
        await this.$store.dispatch("getAccessories")
        await this.$store.dispatch("getRooms")
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },
  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import '../../../style/automation.css';
</style>